
.service-heading{
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    position: sticky;
    top: 0px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 5px ;
  }
  
  .service-list-red{
    background: rgb(255, 155, 155);
    /* border: red solid 10px; */
  }
  
  .service-list-green{
    background: rgb(131, 255, 131);
    /* border: green solid 10px; */
  }
  
  .service-list-orange{
    background: rgb(255, 255, 114);
    /* border: orange solid 10px; */
  }
  
  .service-list{
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px 30px;
  }
  
  .service-list-col{
    /* color: green; */
    padding: 20px;
    padding: 10px;
    border-radius: 20px;
    display: grid;
    gap: 10px;
  }
  
  .service-list-head{
    text-align: center;
    font-size: 1.1rem;
    font-weight: 600;
    background: black;
    color: white;
    padding: 5px;
    margin: -10px;
    border-radius: 20px 20px 0 0;
    margin-bottom: 0px;
  }
  .service-list-data{
    display: grid;
    grid-template-columns: 1fr 2fr;
    /* font-size: 1.2rem; */
  }
  .service-list-data-heading{
    font-weight: 700;
  }
  .service-list-data-btn >span{
    text-align: center;
    /* margin: 10px; */
    background-color: black;
    color: white;
    font-size: 1rem;
    padding: 5px 30px;
    border-radius: 20px;
    margin: 0 10px 0 0;
  }
  
  
  @media only screen and (max-width: 600px) {
  .service-list{
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .service-heading{
    font-size: 1.5rem;
  }
  }