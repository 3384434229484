.nav-bar,
.nav {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  gap: 50px;
  align-items: center;
  padding: 20px ;
  background-color: black;
  color: white;
  /* position: sticky;
  top: 0px; */
}
.nav-bar{
  justify-content: space-between;
  padding: 5px 30px;
}
.nav-logo {
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
  text-decoration: none;
}
.nav-logo img{
  width: 50px;
}

.nav-menu-options {
  display: flex;
  align-items: center;
  gap: 70px;
}
.nav-menu-options > a {
  color: white;
  text-decoration: none;
  /* cursor: grab; */
  cursor: pointer;
}
.nav-bar-button,
.nav-menu-options > div {
  color: black;
  background-color: white;
  padding: 5px 30px;
  border-radius: 30px;
  font-weight: 600;
  cursor: pointer;
}

.nav-menu-drop {
  display: none;
}

@media only screen and (max-width: 600px) {
  .nav {
    padding: 10px 20px;
  justify-content: space-between;

  }
  .nav-menu-drop-options>a{
    color: white ;
  }
  .nav-menu-drop-options>div{
    background-color: white;
    text-align: center;
    font-weight: 600;
    color: black ;
  }
  .nav-menu-options {
    display: none;
  }
  .nav-menu-drop {
    display: grid;
  }
  .nav-menu-drop-icon {
    font-size: 1.8rem;
    border: solid 1px;
    /* border-radius: 50%; */
    border-radius: 8px;
    width:  35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-menu-drop-options {
    display: none;
    /* position: relative; */
  }

  .nav-menu-drop:hover .nav-menu-drop-options{
      position: fixed;
      right: 0;
      top: 40px;
      background: rgb(0, 0, 0);
      padding: 10px ;
      display: grid;
      gap: 20px;
      z-index: 1;
  }
  
}
