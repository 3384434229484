@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Pacifico&family=Playpen+Sans:wght@100;200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Serif:ital,opsz,wght@0,8..144,100;0,8..144,200;0,8..144,300;0,8..144,400;0,8..144,500;0,8..144,600;0,8..144,700;0,8..144,800;0,8..144,900;1,8..144,100;1,8..144,200;1,8..144,300;1,8..144,400;1,8..144,500;1,8..144,600;1,8..144,800;1,8..144,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

*{
  font-family: "poppins";
}


/* ---------------------------------------------------------------------------------------------- */


.error-page{
  text-align: center;
}
 /* ----------------------------------------------- */
 .service-delete-button{
   position: fixed;
   bottom: 10px;
  right: 20px;
  font-size: 2rem;
  color: white;
  background-color: black;
  width:  60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: solid 3px;
}

/* ----------------------------------------------- */

.popup-alert{
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.801); 
}
.popup-alert-content{
  background-color: white;
  padding: 10px 30px;
  border-radius: 12px;
}
.popup-alert-heading{
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.popup-alert-options{
  gap: 20px;
  display: flex;
  flex-direction: row-reverse;
  font-size: 1.2rem;
  font-weight: 500;
  align-items: center;
}
.popup-alert-options>div{
  background-color: black;
  color: white;
  padding: 2px 20px;
  border-radius: 20px;
  cursor: pointer;
}
.popup-alert-options>span:hover{
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
}


@media only screen and (max-width: 600px) {
  .service-delete-button{
  font-size: 1.6rem;
  width:  50px;
  height: 50px;
}
}
