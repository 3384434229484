.home-page-header{
  text-align: center;
}
.home-page-header > img {
  padding: 10px 30px;
  border-radius: 20px;
  width: 100px;
  filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
  /* background-color: black; */
}
.home-page-logout {
  text-align: center;
  margin: 50px;
}

.home-page-logout span {
  background-color: black;
  color: white;
  padding: 5px 30px;
  border-radius: 20px;
  font-size: 1.2rem;
}

.home-page-cols {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* padding: 150px; */
  width: 80%;
  margin: 20px auto;
  align-items: center;
  gap: 20px;
  /* border: solid; */
  padding: 40px 20px;
  border-radius: 30px;

  box-shadow: 4px 4px 6px 0 #6363637c, 4px 4px 6px 0 #c4c4c480,
    inset 4px 4px 6px 0 #fff3, inset 4px 4px 6px 2px rgba(0, 0, 0, 0.244);
}

.home-page-cols > a > img {
  width: 80%;
  border-radius: 20px;
}

.home-page-cols > a {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 40px; */
  text-align: center;
  font-size: 2rem;
  text-decoration: none;
  color: black;
  /* background: gray; */
}

@media only screen and (max-width: 600px) {
  .home-page-cols {
    grid-template-columns: 1fr;
    gap: 40px;
  }
} 
