
.login-main{
    display: flex;
    flex-direction: column;
    gap: 50px;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-header img{
    width: 120px;
}

.login-content-heading-1{
    font-size: 1.7rem;
    font-weight: 700;
}

.login-form form{
    padding: 20px 0;
    display: grid;
    gap: 25px;
    text-align: center;
}

    

.login-form-input{
    display: flex;
    align-items: center;
    width: 300px;
    height: 35px;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.login-form-input>span{
    /* padding: 20px; */
    /* background-color: aquamarine; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
    height: 48px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    border-radius: 50%;
    
}

    

    
.login-form-input>input{
    border: none;
    width: 100%;
    border-radius: 50px;
    font-size: 1rem;
    background: transparent;
    padding-left: 10px;
    height: 100%;
}

.login-form>form>button{
    background-color: black;
    color: white;
    border: none;
    border-radius: 50px;
    width: 150px;
    /* margin: auto; */
    font-size: 1.2rem;
    font-weight: 600;
    padding: 5px;
    margin: 10px auto;
}


    
    
    