
/* ---------------------------------------------------------------------------------------------- */
table{
    width: 100%;
    padding: 20px;
    margin-top: 20px;
  }
  
  th{
    text-align: left;
    padding: 10px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 1.1rem;
    position: sticky;
    top: 58px;
  }
  
  .goc-dashboard-table  tr>td:nth-child(1){
    width: 10%;
  }
  
  .goc-dashboard-table tr>td:nth-child(2){
    width: 20%;
  }
  
  .goc-dashboard-table tr>td:nth-child(3){
    width: 15%;
  }
  
  .goc-dashboard-table  tr>td:nth-child(4){
    width: 25%;
  }
  
  .goc-dashboard-table tr>td:nth-child(5){
    width: 25%;
  }
  
  
  td {
    text-align: left;
    padding: 15px 40px 15px 10px;
  }
  
  tr:nth-child(odd) {
    background-color: rgb(209, 209, 209);
}

/* ====================================================================================== */

.ft-dashboard-table tr>td:nth-child(4){
  width: 18%;
}

.ft-dashboard-table tr>td:nth-child(6){
  width: 25%;
}